.page-banner {
  height: 500px;
  background-color: #e7f8ee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 189px;
  position: relative;
  overflow: hidden;
}

.download-section {
  background-color: #309255;
  position: relative;
  overflow: hidden;
}

.download-section .app-shape-1 {
  width: 560px;
  height: 560px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  left: 70px;
  bottom: 85px;
  position: absolute;
}

.download-section .app-shape-2 {
  width: 560px;
  height: 560px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  left: 31%;
  top: 60px;
  position: absolute;
}

/* .download-section .app-shape-3 {
    width: 560px;
    height: 560px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    right: 15%;
    bottom: 70%;
    position: absolute
} */

.download-section .app-shape-4 {
  width: 560px;
  height: 560px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  right: -243px;
  top: -50px;
  position: absolute;
}

/* .course-main-page {
  height: 380px;
} */

.shape-0 {
  position: absolute;
  top: 130px;
  right: 10px;
  height: 430px;
}

.page-banner-content .title span:before {
  position: absolute;
  content: "";
  background-image: url(../images/shape_4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 120px;
  height: 13px;
  left: 50%;
  bottom: -10px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.shape-3 {
  position: absolute;
  height: 506px;
  top: 70px;
  right: 30px;
}

.my-course {
  padding-top: 144px;
  display: flex;
  /* justify-content: /; */
  align-items: center;
  /* margin-left: -60px; */
}

.page-banner .shape-3 {
  position: absolute;
  height: 130px;
  bottom: 0;
  right: 0;
}

.all-contain {
  display: flex;
}

.shape-icon-box .box-content .box-wrapper {
  background-color: #309255;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.shape-icon-box .box-content .box-wrapper i {
  font-size: 70px;
  color: #fff;
  display: block;
  line-height: 1;
}

.reward-list {
  margin-top: 90px;
}

.author-pic {
  /* margin-top: 90px; */
  position: absolute;
  top: 45%;
  right: 12%;
}

.shape-author {
  position: absolute;
  border-radius: 50%;
  top: 312px;
  right: 187px;
}

.shape_24 {
  margin-top: -110px;
  /* position: ; */
}

.reward-icon {
  height: 70px;
}

.courses-wrapper {
  padding-top: 20px;
}

.courses-all {
  background-color: #fff;
}

.shape-icon-box .box-content {
  width: 160px;
  height: 160px;
  text-align: center;
  border: 2px solid rgba(48, 146, 85, 0.2);
  border-radius: 50%;
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  top: 270px;
}

.icon-shape-1 {
  position: absolute;
  height: 50px;
  top: 250px;
  right: 430px;
}
.ptb50 {
  padding: 50px 0px;
}
.duration {
}

@media (max-width: 576px) {
  .my-course {
    padding-top: 30px;
  }
}
/* 
@media only screen and (max-width:667px) and (min-width:390px) {
    .my.course {
        padding: 0;
        margin-top: -40px;
    }

} */
