.contact-icons {
  height: 60px;
}

.icon-shape-1 {
  position: absolute;
  height: 50px;
  top: 250px;
  right: 430px;
}

.contact-text {
  line-height: 1.5;
}

/* .contact-text-2 {
  padding-top: 20px;
  margin-left: 20px;
} */
.contact-mail {
  font-size: 1.2rem;
}

/* ================================= */
.contact-hero-section {
}
.contact-hero-section-logo {
  position: relative;
}
.shape-icon-box {
  position: relative;
}
.shape-icon-box .box-content {
  width: 140px;
  height: 140px;
  text-align: center;
  border: 2px solid rgba(48, 146, 85, 0.2);
  border-radius: 50%;
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  transform: translatey(50%);
}

.shape-author-box {
  position: relative;
}

.shape-author-img {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 60%;
  transform: translateX(-50%);
  transform: translatey(50%);
}

.shape-author-size {
  width: 170px;
}

.shape-0-img {
  height: 300px;
  position: absolute;
  right: -24%;
  top: 0;
}

.contact-get-section-box {
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 20px;
}
.contact-details-left {
  background-color: #e7f8ee;
  border-radius: 15px;
}

.contact-form-box .title span {
  color: #309255;
  position: relative;
}

.contact-gMap-section .container .contact-us-map {
  width: 100%;
  border-radius: 15px;
}

@media (max-width: 1199.98px) {
  /* .shape-author-img {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    transform: translatey(50%);
  } */

  .shape-0-img {
    height: 300px;
    right: -23%;
    top: 0;
  }
}

@media (max-width: 991.98px) {
  .shape_23 {
  }

  .single-contact-info {
    padding: 5px 0;
  }
  .shape-0-img {
    height: 260px;
    right: -36%;
    top: 0;
  }
}

@media (max-width: 576px) {
  .page-banner-content {
    margin: 0;
  }
}
