.popup {
  overflow: hidden;
}

.hero-padding-top {
  padding: 60px;
}
.hero-padding-hero-top {
  padding: 80px;
}
.pb-50 {
  padding-bottom: 50px !important;
}
@media (max-width: 992px) {
  .hero-padding-top {
    padding: 25px 0;
  }
}

@media (max-width: 576px) {
  .hero-padding-hero-top {
    padding: 40px 0;
  }
}
